.carouselWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;

  min-height: 120px;
}

.gradient-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
  pointer-events: none;
}

.gradient-right {
  position: absolute;
  right: 0;
  top: 0;
  width: 200px;
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 20%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 10;
  pointer-events: none;
}

.partnerLogo {
  width: 150px;
  height: 80px;
  object-fit: contain;
}

@media (max-width: 767px) {
  .gradient-left,
  .gradient-right {
    display: none;
  }
}